export const productConfigObjectMap = new Map();

productConfigObjectMap.set('mini-app', {
    network: 'Vodacom',
    productId: 25,
    productTitle: 'MiniApp',
    endpoint: 'mini-app',
    initialMetricToDisplay: 'dailyFiveEngagement',
    dailyFiveLineGraphLabels: ['Unique Players', 'Questions Served', 'Players Awarded', 'Upsells'],
    predictorLineGraphLabels: ['Entries', 'Upsells', 'Logins', 'Unique Visits'],
    currencySymbol: `R`
});

productConfigObjectMap.set('freeplay-predictor', {
    network: 'Vodacom',
    productId: 55,
    productTitle: 'Freeplay Predictor',
    endpoint: 'freeplay-predictor',
    initialMetricToDisplay: 'predictorEngagement',
    predictorLineGraphLabels: ['Entries', 'Upsells', 'Logins', 'Unique Visits'],
});

productConfigObjectMap.set('stop-the-clock', {
    network: 'Vodacom',
    productId: 3,
    partnerId: 11,
    productTitle: 'Stop The Clock',
    endpoint: 'stop-the-clock',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'vc-fanclash-quizinc-03',
    campaignIdTitle: 'Vodacom Stop the Clock',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-quizinc-03', 'vc-fanclash-vodacom-quizinc-stc-weekly-01']
});

productConfigObjectMap.set('quiz-inc-head-2-head', {
    network: 'Vodacom',
    productId: 21,
    partnerId: 20,
    productTitle: 'QuizInc Head-2-Head',
    serviceId: 'vc-fanclash-quizinc-04-free',
    endpoint: 'quiz-inc-head-2-head',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    freePlayServiceId: 'vc-fanclash-quizinc-04-free',
    vipServiceId: 'vc-fanclash-quizinc-04',
    campaignIdTitle: 'QuizInc Head 2 Head',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-quizinc-04', 'vc-fanclash-vodacom-quizinc-tb-weekly-01']
});

productConfigObjectMap.set('beat-the-jams', {
    network: 'Vodacom',
    productId: 18,
    partnerId: 19,
    productTitle: 'Beat The Jams',
    serviceId: 'vc-fanclash-musictrivia-00',
    endpoint: 'beat-the-jams',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    freePlayServiceId: 'vc-fanclash-musictrivia-00',
    vipServiceId: 'vc-fanclash-beatthejams-01',
    vvipServiceId: 'vc-fanclash-beatthejams-02',
    campaignIdTitle: 'Vodacom Music Trivia VIP',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-beatthejams-01', 'vc-fanclash-beatthejams-02']
});

productConfigObjectMap.set('quiz-inc', {
    network: 'Vodacom',
    productId: 5,
    partnerId: 5,
    productTitle: 'Quiz Inc',
    endpoint: 'quiz-inc',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    ussdWebGraph: ['Unique Players USSD', 'Unique Players Web'],
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    campaignIdTitle: 'Vodacom Quiz Inc Daily',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-quizinc-01', 'vc-fanclash-vodacom-quizinc-weekly-01']
});

productConfigObjectMap.set('master-stream', {
    network: 'Vodacom',
    productId: 29,
    partnerId: undefined,
    productTitle: 'MasterStream',
    endpoint: 'master-stream',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: ['Unique Logins', 'Video Views'],
    currencySymbol: `R`,
    campaignIdTitle: 'Vodacom MasterStream',
    serviceId: 'vc-fanclash-masterstream-01'
})

productConfigObjectMap.set('quiz-inc-aap', {
    network: 'Vodacom',
    productId: 24,
    partnerId: 5,
    productTitle: 'Quiz Inc All Access Pass',
    endpoint: 'quiz-inc-aap',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`
});

productConfigObjectMap.set('unlock-three', {
    network: 'Vodacom',
    productId: 6,
    partnerId: 12,
    productTitle: 'Unlock 3',
    endpoint: 'unlock-three',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    subscriptionGraph: 'All Subscriptions',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'vc-fanclash-quizinc-05',
    campaignIdTitle: 'Vodacom Unlock 3',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-quizinc-05', 'vc-fanclash-vodacom-quizinc-uw-weekly-01']
});

productConfigObjectMap.set('mzansi-games', {
    network: 'MTN',
    productId: 4,
    partnerId: 1,
    productTitle: `Y’ello Rush`,
    endpoint: 'mzansi-games',
    serviceId: 'Mzansi Games Contest',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    subscriptionGraph: 'All Subscriptions',
    engagementGraph: 'Unique Players',
    ussdWebGraph: ['Unique Players USSD', 'Unique Players Web'],
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`
});

productConfigObjectMap.set('game-to-win', {
    network: 'MTN',
    productId: 1,
    partnerId: 69,
    productTitle: '*Game2Win#',
    endpoint: 'game-to-win',
    initialMetricToDisplay: 'productRevenue',
    serviceId: 'USSD Gaming- Daily',
    revenueGraph: 'Gross Revenues',
    subscriptionGraph: 'All Subscriptions',
    engagementGraph: ['Total Players', 'Total Interactions'],
    ussdWebGraph: ['Unique Players USSD', 'Unique Players Web'],
    lineGraphLabels: ['Opening Base', 'Closing Base', 'Subscribed', 'Grace', 'Interested', 'Pending', 'Suspended', 'Unsubscribed', 'Daily Net'],
    currencySymbol: `R`
});

productConfigObjectMap.set('unlock-more', {
    network: 'MTN',
    productId: 0,
    partnerId: 23,
    productTitle: 'Unlock More',
    endpoint: 'unlock-more',
    initialMetricToDisplay: 'productSubscription',
    bannerClicksGraph: ['Banner Clicks', 'Unique Conversions'],
    totalPlaysGraph: ['Total Engaged'],
    currencySymbol: `R`,
    lineGraphLabels: ['Unique Users', 'Total Users'],
});

productConfigObjectMap.set('penrose-awards', {
    network: 'Penrose',
    productId: 999,
    productTitle: 'Awarding Spend',
    endpoint: 'penrose-awards',
    initialMetricToDisplay: 'awardSpend',
    awardsGraph: ['Award Spend', 'Users Awarded'],
    currencySymbol: `R`
});

productConfigObjectMap.set('campaign-ids', {
    network: 'Penrose',
    productId: 666,
    productTitle: 'Campaign IDs',
    endpoint: 'campaign-ids',
});

productConfigObjectMap.set('vodacom-fantasy', {
    network: 'Vodacom',
    productId: 8,
    productTitle: 'Vodacom Fantasy SA',
    endpoint: 'vodacom-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    predictorLineGraphLabels: ['Entries', 'Upsells', 'Logins', 'Unique Visits'],
    currencySymbol: `R`
});

productConfigObjectMap.set('mtn-cdl-fantasy', {
    network: 'MTN',
    productId: 13,
    productTitle: 'MTN Ivory Coast',
    endpoint: 'mtn-cdl-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('mtn-zmb-fantasy', {
    network: 'MTN',
    productId: 17,
    productTitle: 'MTN Zambia',
    endpoint: 'mtn-zmb-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('mtn-cmr-fantasy', {
    network: 'MTN',
    productId: 14,
    productTitle: 'MTN Cameroon',
    endpoint: 'mtn-cmr-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('mtn-nga-fantasy', {
    network: 'MTN',
    productId: 15,
    productTitle: 'MTN Nigeria',
    endpoint: 'mtn-nga-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('eth-et-fantasy', {
    network: 'Ethiotel',
    productId: 16,
    productTitle: 'Ethiotel Ethopia',
    endpoint: 'eth-et-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('airtel-nga-fantasy', {
    network: 'Airtel',
    productId: 22,
    productTitle: 'Airtel Nigeria',
    endpoint: 'airtel-nga-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `$`
});

productConfigObjectMap.set('vc-mzansi-games', {
    network: 'Vodacom',
    productId: 23,
    partnerId: 4,
    productTitle: 'Mzansi Games',
    endpoint: 'vc-mzansi-games',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    subscriptionGraph: 'All Subscriptions',
    engagementGraph: 'Unique Players',
    ussdWebGraph: ['Unique Players USSD', 'Unique Players Web'],
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    campaignIdTitle: 'Vodacom Mzansi Games',
});

productConfigObjectMap.set('voucher-awards', {
    network: 'Derisk',
    productId: 1000,
    productTitle: 'Voucher Awards',
    endpoint: 'voucher-awards',
});

productConfigObjectMap.set('mzansi-rhythm', {
    network: 'MTN',
    productId: 35,
    partnerId: 49,
    productTitle: 'Mzansi Rhythm',
    serviceId: 'mzansi-rhythm-free',
    endpoint: 'mzansi-rhythm',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    freePlayServiceId: 'mzansi-rhythm-free',
    vipServiceId: ['mzansi-rhythm-daily', 'mzansi-rhythm-weekly', 'mzansi-rhythm-monthly'],
    weeklyPricePoint: true,
    pricePointServiceIds: ['mzansi-rhythm-daily', 'mzansi-rhythm-weekly', 'mzansi-rhythm-monthly'],
});

productConfigObjectMap.set('ais-fantasy', {
    network: 'AIS',
    productId: 34,
    productTitle: 'Fantasy Football',
    endpoint: 'ais-fantasy',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed'],
    currencySymbol: `$`
});

productConfigObjectMap.set('ais-mmr', {
    network: 'AIS',
    productId: 33,
    productTitle: 'Megamatch Rivals',
    endpoint: 'ais-mmr',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed'],
    currencySymbol: `$`
});

productConfigObjectMap.set('rugby-zone', {
    network: 'Vodacom',
    productId: 28,
    // partnerId: 11,
    productTitle: 'Rugby Zone',
    endpoint: 'rugby-zone',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'rugby-free',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-vodacom-fl-rugby-daily-01', 'vc-fanclash-vodacom-fl-rugby-weekly-01', 'vc-fanclash-vodacom-fl-rugby-monthly-01'],
    campaignIdTitle: 'Vodacom Rugby Zone',
});

productConfigObjectMap.set('motorsport-zone', {
    network: 'Vodacom',
    productId: 27,
    productTitle: 'Motorsport Zone',
    endpoint: 'motorsport-zone',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'motosport-free',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-vodacom-fl-motorsport-daily-01', 'vc-fanclash-vodacom-fl-motorsport-weekly-01', 'vc-fanclash-vodacom-fl-motorsport-monthly-01'],
    campaignIdTitle: 'Vodacom Motosport Zone',
});

productConfigObjectMap.set('cricket-zone', {
    network: 'Vodacom',
    productId: 26,
    productTitle: 'Cricket Zone',
    endpoint: 'cricket-zone',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'cricket-free',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-vodacom-fl-cricket-daily-01', 'vc-fanclash-vodacom-fl-cricket-weekly-01', 'vc-fanclash-vodacom-fl-cricket-monthly-01'],
    campaignIdTitle: 'Vodacom Cricket Zone',
});

productConfigObjectMap.set('chess-masters', {
    network: 'Vodacom',
    productId: 36,
    productTitle: 'Chess Masters',
    endpoint: 'chess-masters',
    initialMetricToDisplay: 'productRevenue',
    revenueGraph: 'Gross Revenues',
    engagementGraph: 'Unique Players',
    lineGraphLabels: ['Subscribed', 'Unsubscribed', 'Organic Web', 'Organic USSD', 'SIM Push', 'Global Unsubs'],
    currencySymbol: `R`,
    serviceId: 'vc-fanclash-quizinc-cm-f-daily-01',
    weeklyPricePoint: true,
    pricePointServiceIds: ['vc-fanclash-quizinc-cm-f-daily-01', 'vc-fanclash-quizinc-cm-f-weekly-01', 'vc-fanclash-quizinc-cm-f-monthly-01'],
    campaignIdTitle: 'Vodacom Chess Masters',
});